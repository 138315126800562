//
// Events archive page | Isotope
//
var months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
var date = new Date();
var eventsDefaultFilter = months[date.getMonth()] + "-" + date.getFullYear();


var eventsDefaultFilter1 ="";
var eventsDefaultFilter2 ="";

if(date.getMonth() == 10){ 
    eventsDefaultFilter1 = months[11] + "-" + date.getFullYear();
    eventsDefaultFilter2 = months[0] + "-" + new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear();

}else if(date.getMonth() == 11){ 

    eventsDefaultFilter1 = months[0] + "-" + new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear();
    eventsDefaultFilter2 = months[1] + "-" + new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear();

}else{ 

    eventsDefaultFilter1 = months[date.getMonth() + 1] + "-" + date.getFullYear(); 
    eventsDefaultFilter2 = months[date.getMonth() + 2] + "-" + date.getFullYear();
}  


eventsDefaultFilter=months[date.getMonth()]+"-"+date.getFullYear(); 


var $eventGrid = $(".events_grid").isotope({
    itemSelector: ".tease-events",
    percentPosition: true,
    filter: "."+eventsDefaultFilter+", ."+eventsDefaultFilter1+", ."+eventsDefaultFilter2+"",
    masonry: {
        gutter: 5
    }
});

var filters = {};
$(".events_filter select").on("change", function(e) {
    var filterGroup = $(this).attr("data-filter-group"); // set filter for group
    filters[ filterGroup ] = e.target.value == "" ? e.target.value : "." + e.target.value ;
    var filterValue = concatValues(filters); // combine filters
    $eventGrid.isotope({filter: filterValue});
});
