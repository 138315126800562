/**
 * PrivateMedia Zephr User
 *
 * @author   PrivateMedia
 * @since    6.1
 */

var PrivateMedia_Zephr = {
    options:       {},
    account:       null,
    profile:       null,
    zephr_ready:   false,
    profile_ready: false,
    account_ready: false,

    javascript_features: [
        {
            id:       'login-form',
            selector: '.ze__modal__bk .body',
        }
    ],

    profile_defaults: {
        'first-name': '',
        'last-name': '',
    },

    /**
     * Perform initialisation code such as registering event callbacks.
     * 
     * @returns null
     */
    initialise: function( options ) {
        var self = this;

        self.options = options;

        if ( ! self.zephr_running() ) {
            console.log('Zephr not enabled on the website??');
            jQuery(document).trigger('pm.zephr_ready', self);
            return;
        }

        jQuery('.login_modal a, .wpd-login a, .ze__login').click( self.login );

        jQuery( '.ze__logout' ).click( self.logout );

        // jQuery( '.ze__modal__bk' ).on( 'mousedown', self.close_modal );

        self.issue_login_intent_cookie();

        self.hook_registration_forms();

        //self.load_feature_login_form();

        //self.get_current_user_data();
        
        self.check_and_show_login_modal();

        // Delete intent cookie
        PrivateMedia_Zephr.set_cookie( 'wordpress_logged_in_intent', '', -1 );
    },

    /**
     * Show login modal.
     * 
     * @param {*} ev Event
     */
    login: function( ev ) {
        if ( ev ) {
            ev.preventDefault();
        }
        jQuery('#mandarin_login_modal').modal();
        jQuery('#mandarin_login_modal input[name="email"]').focus();
    },

    /**
     * Log a user out of Zephr. We delete our own cookies and the blaize_session cookie (because Zephr doesn't reliably delete it).
     * 
     * @param {*} ev Event 
     */
    logout: function( ev ) {
        ev.preventDefault();
        
        window.BlaizeSDK.logout( function(error, status) {
            if (error) {
                console.log('Logout', error);
                return;
            }

            PrivateMedia_Zephr.account = null;
            PrivateMedia_Zephr.profile = null;
            // PrivateMedia_Zephr.set_cookie( 'z_account', '' );
            // PrivateMedia_Zephr.set_cookie( 'z_profile', '' );
            // PrivateMedia_Zephr.set_cookie( 'blaize_session', '' );
            
            console.log('Logout', status);
            var url = new URL( window.location.href );
            url.searchParams.set('lc', Math.floor(Math.random() * 10000));
            window.location.href = url.origin;
        } );
    },

    /**
     * Close a modal when the modal background is clicked.
     * 
     * @param {*} ev Event 
     */
    close_modal: function( ev ) {
        // ev.preventDefault();

        var clicked_outside_modal = jQuery( ev.target ).hasClass('ze__modal__bk');

        if ( clicked_outside_modal ) {
            jQuery('.ze__modal__bk').hide();
            //PrivateMedia_Zephr.load_feature_login_form();
        }
    },

    /**
     * Check if we have a 'c_login' query param and show the login modal if we do.
     */
    check_and_show_login_modal: function() {
        var url    = new URL( window.location.href );
		var params = new URLSearchParams( url.search );

        // Show login modal if we have a 'c_login' query param.
        if ( params.has('c_login') && jQuery('#mandarin_login_modal').length ) {
            jQuery('.sign-in').first().trigger('click');
        }
    },

    /**
     * Load the login form Zephr Feature via an API call. The purpose is so we 
     * can reset/clear the login form when the user closes the modal dialog.
     */
    /*load_feature_login_form: function( selector ) {
        var js_feature = PrivateMedia_Zephr.javascript_features[0];
        selector = selector || js_feature.selector;

        jQuery.ajax({
            url: '/zephr/feature-decisions', 
            type: "POST",
            dataType: "json",
            contentType: 'application/json',
            data: JSON.stringify( 
                { 
                    featureIds: [ js_feature.id ] 
                }
            ),
            success: function(data) {
                try {
                    var target      = jQuery( selector );
                    var feature_res = data.featureResults[ js_feature.id ];

                    for (var key in data.resources.uiComponents ) {
                        if ( -1 !== feature_res.indexOf( key ) ) {
                            target.html( data.resources.uiComponents[ key ] );
                            break;
                        }
                    }
                    
                } catch (ex) {
                    console.log('Failed to load feature', PrivateMedia_Zephr.FEATURE_ID_LOGIN_FORM, data )
                }
            }
        });
    },*/

    /**
     * Check if the user is currently logged into Zephr.
     * 
     * @returns bool
     */
    is_user_logged_in: function() {
        // can also use window.Zephr.authenticated or something.
        return typeof this.account !== 'undefined';
    },

    /**
     * Check Zephr is running on the page.
     * 
     * @returns bool
     */
    zephr_running: function() {
        return window.Zephr;
    },

    /**
     * Register a 'form pre-processor' on all user registration forms. Here we edit the user-attributes prior to registering the user.
     */
    hook_registration_forms: function() {
        jQuery( 'form[id^="zephr-registration-form-"]' ).each( function() {

            var form_id   = jQuery( this ).attr( 'id' );
            var form_slug = form_id.replace( 'zephr-registration-form-', '' );
    
            window.Blaize.registerFormPreProcessor( form_slug, function( formData ) {
    
                if ( ! formData.attributes ) {
                    formData.attributes = {};
                }

                var newsletters = PrivateMedia_Zephr.options.newsletter_lists || {};

                for ( var key in newsletters ) {
                    formData.attributes[ key ] = newsletters[ key ];
                }

                return formData;  
            } );
        } );

        // Issue cookie showing login intent when user clicks on a login/registration form button.
        jQuery( '.zephr-registration-form').on('mousedown touchstart', function() {
            PrivateMedia_Zephr.issue_login_intent_cookie( true );
        } );
    },

    issue_login_intent_cookie: function( force ) {

        var blaize_session = PrivateMedia_Zephr.get_cookie( 'blaize_prev_anon_session' );
        if ( ! blaize_session && ! force ) {
            return false;
        }

        var ranInt = Math.floor( Math.random() * 999999999 );
        PrivateMedia_Zephr.set_cookie( 'wordpress_logged_in_intent', ranInt, 0.000694 );

    },

    /**
     * Get the currently logged in users Zephr user data (account, profile/user-atrribtutes).
     * 
     */
    /*get_current_user_data: function() {
        var self = this;
        // try { self.account = JSON.parse( self.get_cookie('z_account') ); } catch(ex) {}
        // try { self.profile = JSON.parse( self.get_cookie('z_profile') ); } catch(ex) {}

        window.BlaizeSDK.getAccount( function(error, account) {
            if (error)
                return;

            self.account       = account;
            self.account_ready = true;
            // self.set_cookie( 'z_account', JSON.stringify( self.account ) );

            jQuery(document).trigger('pm.account_retrieved', self);
            if ( self.account_ready && self.profile_ready )
                jQuery(document).trigger('pm.zephr_ready', self);
        } );

        window.BlaizeSDK.getProfile( function(error, profile) {
            if (error)
                return;

            self.profile       = Object.assign( self.profile_defaults, profile );
            self.profile_ready = true;
            // self.set_cookie( 'z_profile', JSON.stringify( self.profile ) );

            jQuery(document).trigger('pm.profile_retrieved', self);
            if ( self.account_ready && self.profile_ready )
                jQuery(document).trigger('pm.zephr_ready', self);
        } );
    },*/

    /**
     * Utility function for setting a cookie.
     * 
     * @param string name   Cookie name.
     * @param string value  Cookie value/data.
     * @param int    days   Expiry in days.
     */
    set_cookie: function( name, value, days ) {
        var expires = "";

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
        }
        
        document.cookie = name + "=" + value + expires + "; path=/";
    },
    
    /**
     * Utility function for getting a cookie by its name.
     * 
     * @param string cookie_name  Cookie name.
     * @returns 
     */
    get_cookie: function( cookie_name ) {
        var name = cookie_name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    
    /**
     * Utility function for getting a cookie that begins with cookie_name
     * 
     * @param string cookie_name  Cookie name.
     * @returns 
     */
    get_cookie_starts_with: function( cookie_name ) {
        var name = cookie_name;
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

};

