/**
 * Private Media Core JS.
 */

// Ensure jQuery is declared.
if ($ == undefined) {
    if ( jQuery != undefined)
        var $ = jQuery;
    else if ( window.jQuery != undefined)
        var $ = window.jQuery;
    else if ( window.$ != undefined)
        var $ = window.$;
}