var id = 0;
function ChooseProduct(el, hash, coupon_code){
	id = isNaN(id) ? 0 : id;
	removeDiscount();

	if(!$("input#coupon_code").val()){
		$("input#coupon_code").val(coupon_code);
	}
	
	$radio = $(el);
	
	if( $radio.length === 0) return;

	//staging : yearly - prod : man-prem-annual
	if ( $( "#phone" ).length ) {

		if($(el + "_handle").val() == 'man-prem-annual'){
			$("#phone").prop('required',true);
			$("label[for='phone']").html($("label[for='phone']").html().replace("*", ""));
			$("label[for='phone']").html($("label[for='phone']").html().replace("Phone Number", "Phone Number *"));
			$("#input-phone .question-circle").show();

		}else{
			$("#phone").prop('required',false);
			$("label[for='phone']").html($("label[for='phone']").html().replace("Phone Number *", "Phone Number"));
			$("#input-phone .question-circle").hide();
		}

	}


	if(hash) {
		location.hash = hash;

		$('#choosen_product_name').val($("." + 'product_title_' + hash).first().val());
		$('#choosen_product_price').val($("." + 'product_original_price_' + hash).first().val());
		$('#choosen_product_coupon_code').val(""); 
		$('#choosen_product_sale_price').val(""); 
		$('#choosen_product_sku').val($("#" + 'signup_product_id_' + hash + '_handle').first().val()); 
		$('#choosen_product_code').val(hash);
		$('.plan_interval_string').html($("." + 'product_title_' + hash).first().val());
	}
	
	$("input[name='signup[product_id]']").attr('checked', false);
	$radio.attr('checked', true);
	$('#subflow-0'+id).slideUp();
	id++;
	$('#subflow-0'+id).slideDown();

	$('html, body').scrollTop( $("#plans").offset().top + ($("#plans").height() * 0.5) );

	validateDiscountCode();
}
function ChangePlan(){
	clearDiscountCode();
	clearSelectedProductCode();
	$('#subflow-0'+id).slideUp();
	id--;
	$('#subflow-0'+id).slideDown();
}

function EditStep( id ){
	$this = $('.form-steps fieldset#'+id);
	$this.nextAll('fieldset').removeClass('current valid')
	$this.addClass('current').removeClass('valid');
}
document.addEventListener("keyup", function(event) {
	if (event.keyCode === 13) { // enter key
		ValidateStep();
	}
});

var processing = 'processing';
function ValidateStep(){
	$this = $('.form-steps fieldset.current');
	if( $this.hasClass(processing) ) return;
	$this.addClass(processing);

	$err = $this.find('.fieldset-error');
	$errList = $err.find('.fieldset-error-list');
	$err.hide();

	if(!mandy_validatePhone( 'phone' )){

		StepIsInvalid( $this, "Your phone number appears to be incorrect" );
		return false;

	}

	var isValid = SubscriptionFlow.FieldsetValidate( $this );
	if( isValid && $this.is("#step-account-info") ){ // Is user creation
		SubscriptionFlow.CheckUserExists( $this.find('input[name="user_email"]').val(), function(data){
			if( data) {
				StepIsInvalid( $this, "This email address is already in use, log in or try another address" );
			}else{

				if(jQuery("#paymentMethodId").val()){

					loadPaymentMethodId();

				}else{
					loadHostedPage();	
				}
				

				StepIsValid( $this );

			}
		});
	}else if( isValid ){

		setTimeout( function(){ console.log('stop here please 3');  StepIsInvalid( $this, "There appears to be an issue with your submission. Please review the fields below or contact support for assistance." ); }, 8000 );

		getPaymentMethodId();	

		return false;

	}else{
		setTimeout( function(){ StepIsInvalid( $this ) }, 300+(Math.random()*1000) ); // comment below
	}
	// setTimeout is just to even out the UX due to lag on checking if user exists
}

function StepIsValid( $this ){
	$this.removeClass(processing);
	$next = $this.next();
	var data = "";
	$fields = $this.find("input");
	$fields.each(function(e){
		if( $(this).attr("type") != "password" ) data += $(this).val()+'\n';
		if( $(this).attr("type") == "password" ) data += "•••"+'\n'; // Don't show actual length in preview
	});

	if($this.attr('id') == 'step-payment'){
		$this.find('.content-accepted pre').html(jQuery("#cc_details").val());
	}else{
		$this.find('.content-accepted pre').html(data);
	}
	
	// Next
	$this.removeClass('current').addClass('valid');
	$next.addClass('current');
	$next.find("input").first().focus();
}

function StepIsInvalid( $this, $error ){
	$this.removeClass(processing);
	$err = $this.find('.fieldset-error');
	$errList = $err.find('.fieldset-error-list');

	$errList.empty();
	//$errList.append($("<li>The highlighted fields below are invalid</li>"));
	if( $error ) $errList.append($("<li>"+$error+"</li>"));

	$err.fadeIn();
}

var SubscriptionFlow = (function(){

	var submitted = false;
   	//window.onbeforeunload = function(){ if(submitted) return "Closing this page may cause issues with payment."; }
	$('#add-subscription').on( "click", function() {

		$('#addsubscription').find('fieldset').removeClass('valid');

		$btn = $('#addsubscription').find('input[name="add-subscription"]');
		$btn.attr('disabled', true);
		$btn.val('Please wait..');

		submitted = true;

		$('#addsubscription').submit(); 
   		
	});
	$('.form-steps fieldset input').on('focus', function(e){
		$(this).parent().removeClass("field-error");
	});
	$('.form-steps fieldset input').on('blur', function(e){
		RequiredField( $(this) );
	});

	function validateEmail(email){ // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
	    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(String(email).toLowerCase());
	}

	function CheckUserExists( username, callback ){
		$.post('/wp-admin/admin-ajax.php', {
            username: username,
            action: 'PM_getUserID'
        }, function(data) {
			callback(data);
        }, 'json');
	}

	function RequiredField( field ){
		var valid = true;
		field.parent().removeClass("field-error");

		if( field.attr("type") == "email" ){
			if(! validateEmail(field.val()) ) valid = false;
		}else if( $(this).attr('name') == 'signup[cc_card_number]'){
			if(! Payment.fns.validateCardNumber( $(this).val() ) ) valid = false;

		}else if( $(this).attr('name') == 'signup[cc_cvv]'){
			if(! Payment.fns.validateCardCVC( $(this).val() ) ) valid = false;

		}else if( $(this).attr('name') == 'signup[cc_expiration_month]'){
			if(! Payment.fns.validateCardExpiry( $(this).val() ) ) valid = false;
		}

		if( field.attr('required') && field.val() == "") valid = false;

		if(! valid) field.parent().addClass("field-error");
		return valid;
	}

	function FieldsetValidate( fieldset ){
		// Just checks if required fields aren't empty
		var valid = true;
		fieldset.find('input').each(function(e){
			if(! RequiredField( $(this) ) ) valid = false;
		});
		return valid;
	}

	return {
		FieldsetValidate:FieldsetValidate,
		CheckUserExists:CheckUserExists,
	}

})();



$(document).ready(function(){
	$("#apply-coupon-code").on('click',function(e){
		validateDiscountCode();
	});
	$("#clear-coupon-code").on('click',function(e){
		clearDiscountCode();
	});

    $( "#phone" ).blur( function() {
        mandy_validatePhone( 'phone' );
    });

	$('input#user_email').on('blur', function( e ) {
		validateDiscountCode();
	});

	var hash = window.location.hash.replace('#', '');
	if ( hash ) {
		
		//Monthly
		if(hash == '4933042'){
			hash = '8a3699647d4b5cc0017d4f6013aa0808';
		//Quarterly	
		}else if(hash == '5248020'){
			hash = '8a3699647d4b5cc0017d4f6013900806';
		//Annual		
		}else if(hash == '4933041'){
			hash = '8a3699647d4b5cc0017d4f6013c3080a';
		}

		$('#choosen_product_code').val(hash);
	}
});

function clearSelectedProductCode(){
	$('#choosen_product_code').val('');
}

function clearDiscountCode(){
	removeDiscount();
	$("input#coupon_code").val('');
}
function validateDiscountCode(){
	if( $("input#coupon_code").val() == "") return; // dont bother if empty

	// If already making an ajax call: dont make another.
    if ( $("input#coupon_code").length && $("input#coupon_code").is("[processing]") ) {
        return;
    }

	$("input#coupon_code").attr("processing", true);

    removeDiscount();

	$overlay = $("#apply-coupon-code").parent().parent().find('.ajax-submit-overlay');
	$overlay.fadeIn();

	var email = jQuery('#user_email').length ? jQuery('#user_email').val() : '';
    var validation_context = jQuery('form#addsubscription').length ? 'new_subscription' : 'update_subscription';

	$.post('/wp-admin/admin-ajax.php', {
        coupon_code: $("input#coupon_code").val(),
        product_id: $("input[name='signup[product_id]']:checked").val(),
		email: email,
		validation_context: validation_context,
        action: 'PM_VerifyDiscountCode'
    }, function(data) {

		$overlay.fadeOut();

    	if( data.success ){
    		if ( ! data.recurring){
    			$(".recurring-coupon-message").slideDown();
    		}else{
    			$(".recurring-coupon-message").hide();
    		}

			var added_discount = 0;
			
			var default_weekly_price = 0;
			var default_weekly = '$0';
			
			var product_price = '';
			
			var active_section = get_dom_in_scope();
			
			var selected_product_code = $('#choosen_product_code').val();
			product_price = $("." + 'product_price_' + selected_product_code).first().val();

			var check_weekly_discount_applied = $(active_section).find("del.strikedout-discount").length;
	
			if ( check_weekly_discount_applied > 0 ){
				default_weekly = $(active_section).find("del.strikedout-discount").first().text();
			} else {
				 default_weekly = $(active_section).find(".coupon-weeekly-price").first().text();
			}
			default_weekly_price = default_weekly.replace("$", "");
			
    		var amount_percentage = data.percentage;
    		
    		var rs_price = 0;
    		
    		var renewed_weekly = 0;
    		
    		var new_weekly_raw = 0;
    		

    		if(amount_percentage){
    			
    			var percentage = (product_price/100)*(amount_percentage/100);
			
    			rs_price = (product_price/100) - percentage;
	
    			added_discount = rs_price.toFixed(2);

    			addDiscount(added_discount);
    			
    			new_weekly_raw = default_weekly_price *(1-(amount_percentage/100));
    			
    		} else {
    			
    			var new_price = ( product_price/100 ) - ( data.amount_in_cents/100 );

 				new_weekly_raw = default_weekly_price * (new_price/(product_price/100));

 				added_discount = new_price.toFixed(2);
 				
				addDiscount(added_discount);	  			
    		}

			$('#choosen_product_sale_price').val(added_discount); 
			$('#choosen_product_coupon_code').val($("input#coupon_code").val()); 

			renewed_weekly = new_weekly_raw.toFixed(2);
			$(active_section).find(".coupon-weeekly-price").html('<del class="strikedout-discount">'+default_weekly+'</del>'+' <span>$'+renewed_weekly+'</span>');    		   		

    		


    	}else{
    		$(".danger-coupon-message").text(data.error);
    		$(".danger-coupon-message").slideDown();
    	}
    }, 'json')
	.always(function() {
		$("input#coupon_code").removeAttr( "processing" );
	});
}

function get_dom_in_scope(){

	var dom_in_scope = '';
	
	var selected_product_code = $('#choosen_product_code').val();
	
	$("[id^=subflow-]").each(function(){
		if ($(this).css('display') == 'block') {
			
			$(this).find("label").each(function(){
				
				if ($(this).attr('for') == ('signup_product_id_' + selected_product_code)){
					dom_in_scope = $(this);
				}
				
			});		
		}
	});	
	
	return dom_in_scope;
}

function addDiscount(new_price){
	$("input#coupon_code").addClass("alert-success");
	var product_id = $("input[name='signup[product_id]']").val();
	$label = $("input[name='signup[product_id]']:checked").next().find('.product_card');
	var orig_price = $label.find(".original_price").text();

	$label.addClass("with-discount");
	$label.find(".new_price").text("$"+new_price);
}

function get_dom_in_scope(){
	var dom_in_scope = '';
	
	var selected_product_code = $("input[name='signup[product_id]']:checked").val();
	$("[id^=subflow-]").each(function(){
		if ($(this).css('display') == 'block') {
			
			$(this).find("label").each(function(){
				
				if ($(this).attr('for') == ('signup_product_id_' + selected_product_code)){
					dom_in_scope = $(this);
				}
				
			});		
		}
	});	
	
	return dom_in_scope;
}


function removeDiscount(){
	$(".recurring-coupon-message").hide();
	$(".danger-coupon-message").hide();
	$("input#coupon_code").removeClass("alert-success");
	$label = $("input[name='signup[product_id]']:checked").next().find('.product_card');
	$label.removeClass("with-discount");
	$label.find(".new_price").text('');

	var scope = get_dom_in_scope();
	if ($(scope).find(".strikedout-discount").length){
		var coupon_cleared_price = $(scope).find(".strikedout-discount").first().text();
		$(scope).find(".coupon-weeekly-price").first().text(coupon_cleared_price);	
	}	
	
}

function mandy_validatePhone(elem) {

	var elemen_phone = "input-phone";
	
    var element = jQuery("#" + elemen_phone);
    var elem_value = jQuery("input[name='" + elem + "']").val();
    var phoneExpression = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
    
    jQuery('#' + elemen_phone + ' .frm_error').remove();

    if (typeof elem_value !== "undefined" && elem_value != "") {


        if ( ! elem_value.match( phoneExpression ) ) {
            var msg = "Your phone number appears to be incorrect";

            errorMessage( element, msg );
        	return false;

        }else{

        	return true;

        }

    }else{

    	return true;

    }

}

function errorMessage($element, $msg) {
   	
   	$element.addClass("field-error");
	$element.append("<span class='frm_error'>"+$msg+"</span>");

}
