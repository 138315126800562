/**
 * Mandarin Australia Zephr Customisations
 * 
 * Site specific Zephr customisations
 *
 * @author   PrivateMedia
 * @since    6.2
 */

var Mandarin_Zephr = {

    options: {
        newsletter_lists: {
            'the-juice': true,
            'special-reports': true,
            'the-wrap': true,
            'partner-offers': true,
            'offers-promotions': true,
            'registration-status':"complete",
        },
    },

    sdk_account: null,

    /**
     * Perform initialisation code such as registering event callbacks.
     * 
     * @returns null
     */
    initialise: function() {

        var self = this;
 
        PrivateMedia_Zephr.initialise( self.options );
        self.handle_single_offers();
    },

    handle_single_offers: function() {
        jQuery( 'a.pm-single-offer' ).each( function( e ) {
            try {
                var anchor = jQuery(this);
                var href   = anchor.attr('href');
                var url = new URL( href, window.location.href );

                var cleanUrl = location.protocol + '//' + location.host + location.pathname;
                url.searchParams.set( 'cancel_url', cleanUrl + '?cancel=true' );
                url.searchParams.set( 'success_url', cleanUrl + '?success=true' );
                
                anchor.attr( 'href', decodeURIComponent(url.toString()) );
            } catch (ex) {  }
        });
    },

    /**
     * Show/hide comment messaging based on the user role.
     * 
     * @param {*} sdk_account 
     * @returns void
     */
    handle_comments: function( sdk_account ) {
        sdk_account = sdk_account || Mandarin_Zephr.sdk_account;
        var role = sdk_account.role || 'unknown';

        if ( role == 'customer' ) {
            jQuery('#pm-cmt-customer-msg').removeClass('hidden');
            jQuery('#pm-cmt-premoderate-msg').removeClass('hidden');
        } else if ( role == 'registered' ) {
            jQuery('#pm-cmt-registered-msg').removeClass('hidden');
        } else if ( role == 'anonymous' ) {
            jQuery('#pm-cmt-anonymous-msg').removeClass('hidden');
        }
    },

};

jQuery(document).ready( function() {

    Mandarin_Zephr.initialise();

} );