/**
 * Gated Media Frontend Functionality
 * 
 * @category Class
 * @package  themandarin.com.au
 * @author   Sam Zielke-Ryner
 * @since    6.1
 */

var MaGatedMedia = {

    init: function() {
        // Hook any ebook forms on page
        this.hookEbooks();
    },

    hookEbooks: function() {

        jQuery(document).on( 'frmFormComplete', function( event, form, response ) {
            var formID  = jQuery(form).find('input[name="form_key"]').val() || '';
            var isEbook = ( formID.toLowerCase() ).indexOf( 'ma_ebook' ) !== -1;
            var isReport = ( formID.toLowerCase() ).indexOf( 'ma_report' ) !== -1;
            var isWebinar = ( formID.toLowerCase() ).indexOf( 'ma_webinar' ) !== -1;
            var isWhitepaper = ( formID.toLowerCase() ).indexOf( 'ma_whitepaper' ) !== -1;
            var isOnDemand = ( formID.toLowerCase() ).indexOf( 'ma_on_demand' ) !== -1;

            if ( isEbook || isReport || isWebinar || isWhitepaper || isOnDemand ) {

                var ebookTitle = jQuery( '#ma_ebook_title' ).val();
                var ebookUrl   = jQuery( '#ma_ebook_url' ).val();
                var ancil      = '<p style="color: #000;">We have also sent a link to your inbox so you can easily access it later.</p>';

                if ( isReport ) { 
                    media_type = 'report'; 
                    blurb = 'Thanks for downloading the report';
                }
                else if ( isWebinar ) { 
                    media_type = 'webinar'; 
                    blurb = 'Thanks for accessing the webinar';
                }
                else if ( isWhitepaper ) { 
                    media_type = 'whitepaper'; 
                    blurb = 'Thanks for downloading the whitepaper';
                }
                else if ( isOnDemand ) { 
                    media_type = 'On Demand'; 
                    blurb = 'Thanks for accessing our On Demand content';
                    ancil = '';
                }
                else { 
                    media_type = 'eBook'; 
                    blurb = 'Thanks for downloading the eBook';
                }

                setTimeout( function() {
                    jQuery('.mandy-ebook .frm_forms .frm_message').remove();
                    
                    jQuery('.mandy-ebook .frm_forms').append('<div class="frm_message" role="status"><h4 style="font-size: 1.7em;">' + blurb + '</h4><p style="margin: 20px 0;"><a href="' + ebookUrl + '" target="_blank">Access now: ' + ebookTitle + '</a>.</p>' + ancil + '</div>');
                }, 100);

                ga('send', {
                    hitType: 'event',
                    eventCategory: media_type,
                    eventAction: formID,
                    eventLabel: window.location.href,
                    value: window.location.href
                });

            }
        });

    }
}

jQuery(document).ready( function() {
    MaGatedMedia.init();
} );