var $body = $("body");

function getrecaptchaScript(callback) {

    var scriptUrl = 'https://www.google.com/recaptcha/api.js?render=' + rest_object.recaptcha_sitekey + '&ver=3.0';
    var script = document.createElement('script');
    script.src = scriptUrl;
    script.onload = callback;

    document.body.appendChild(script);
}

//Text resizing function for all archive pages.
var changeTextSelection = $(".text-resizing a");
function textResizing(selector, classToToggle, fontSize) {
    $("." + selector).on("click", function(e) {
        e.preventDefault();
        changeTextSelection.removeClass(classToToggle);
        $(this).addClass(classToToggle);
        $body.removeClass("small_text normal_text big_text");
        $body.css("font-size", fontSize).addClass(selector);
    });
}

// flatten object by concatting values to filter the events
function concatValues( obj ) {
    var value = '';
    for ( var prop in obj ) {
        value += obj[ prop ];
    }
    return value;
}

    
var lazy_opener = (function(){
    var opener = {
        delay:3, // seconds to wait
        current:null, // current hover
        interval:null, // timeout management
        url:null // url
    };
    function init(){
        if (navigator.userAgent.indexOf("MSIE") >= 0) return; // this doesn't work below IE10

        $( window ).scroll(reset);
        $(".lazy-opener").each(function(){
            $(this).addClass("ready");
            $(this).css("transition-duration",opener.delay*0.8+"s"); // for animations
            $(this).css("transition-delay",opener.delay*0.2+"s"); // for animations

            $(this).parent().mouseenter(function(){
                opener.current = $(this).find('.lazy-opener');
                opener.url = opener.current.data("url");

                reset();
                opener.interval = setTimeout(function(){
                    opener.current.closest('.post-link').addClass("opening");
                    $("body").addClass("lazy-opening");
                    
                    setTimeout(function(){
                        window.location = opener.url;
                    },250); // for animation

                }, opener.delay*1000);
            });
            $(this).parent().mouseleave(reset);
        });
    }

    function reset(){
        clearTimeout( opener.interval );
    }

    return {
        init:init
    }
})();


$(document).ready(function(){
    $navMain = $(".nav-main");
    
    var offset = 150;

    var section = $('#aside_job_description').closest('section');
    var section_top = section.length ? section.offset().top : 0;
    var section_height = section.length ? section.height() : 0;

    var aside_limit = $('body').height() - ( section_top + section_height );

    $('#aside_job_description').sticky({
        topSpacing: $navMain.height(),
        bottomSpacing: aside_limit + offset
    });

    $('.sticky-panel').each(function(){

        var section = $(this).closest('section');
        var section_top = section.length ? section.offset().top : 0;
        var section_height = section.length ? section.height() : 0;
        var this_limit = $('body').height() - ( section_top + section_height );
        $(this).sticky({
            topSpacing: $navMain.height(),
            bottomSpacing: this_limit + offset
        });
    });

    footerSticky()
});
$(document).resize(function(){
    $('#aside_job_description').sticky('update');
    $('.sticky-panel').sticky('update');
});

$(window).resize(function(){
    footerSticky();
});

function footerSticky() {    
    if ( $('body').height() < $(window).height() ) {
        $('body').addClass('footer-fixed');
    } else {
        $('body').removeClass('footer-fixed');
    }
}


var SelectPlugin = (function(){
    /* https://github.com/nobleclem/jQuery-MultiSelect */

    var readyClass = 'selectplugin-ready';
    var $selects, selectsLoaded, initCallback;
    
    function init(callback){
        console.log('init');

        $selects = [];
        selectsLoaded = 0;

        initCallback = function(){
            $('body').addClass( readyClass );
            if(callback) callback()
        };
        $('select.selectize, select.job-manager-category-dropdown, .ginput_container_multiselect select').each(function(e){
            var id = $(this).attr('id');

            var label = $('label[for="'+id+'"]').text();
            var labelPlural = label;

            var plural = "s";
            switch ( labelPlural[labelPlural.length-1] ){
                case "y":
                    labelPlural = labelPlural.slice(0, labelPlural.length-1);
                    plural = "ies";
                    break;
                case "s":
                    plural = "";
                    break;
            }
            labelPlural += plural;

            $select = $(this).multiselect({
                columns: 1,
                placeholder: "Select " + label,
                search: true,
                searchOptions: {
                    'default': 'Search ' + labelPlural
                },
                selectAll: false,
                onLoad:initCallback,
                /*
                onOptionClick: function( element, option ) {
                    console.log( $(element) );
                    var maxSelect = 3;
                    if( maxSelect ){
                        // too many selected, deselect this option
                        if( $(element).val().length > maxSelect ) {
                            if( $(option).is(':checked') ) {
                                var thisVals = $(element).val();

                                thisVals.splice( thisVals.indexOf( $(option).val() ), 1 );

                                $(element).val( thisVals );

                                $(option).prop( 'checked', false ).closest('li').toggleClass('selected');
                            }
                        }else if( $(element).val().length == maxSelect ) {
                            $(element).next('.ms-options-wrap')
                                .find('li:not(.selected)').addClass('disabled')
                                .find('input[type="checkbox"]')
                                .attr( 'disabled', 'disabled' );
                        } else {
                            $(element).next('.ms-options-wrap')
                                .find('li.disabled').removeClass('disabled')
                                .find('input[type="checkbox"]')
                                .removeAttr( 'disabled' );
                        }
                    }
                }
                */
            });
            $selects.push( $(this) );
/*
            $dropdown = $select.parent().find('.ms-options-wrap');
            if( $dropdown ){
                console.log($dropdown);
                $dropdown.on('change',function(e){
                    console.log('change',e);
                });
                $dropdown.bind('DOMSubtreeModified',function(e){
                    console.log('DOMSubtreeModified',e);
                });
            }
*/
            $(this).on('change', function(e){
                showFieldClearButton( $(this) );
                updateSelectedParents( $(this) );
            });

            showFieldClearButton( $(this) );

            $clearBtn = $(this).parent().find('.field-clear');
            $clearBtn.attr('fieldid', $selects.length-1);
            if( $clearBtn.length ){
                $(this).parent().find('.field-clear').on('click', function(e){
                    $selects[ $(this).attr('fieldid') ].val('');
                    refresh();
                });
            }
        });
    }
    function updateSelectedParents( $el ){
        $el.find('optgroup option:checked').each(function(e){
            $(this).parent().prev().prop("selected", true);
        });
        //refresh();
    }
    function loaded(){
        selectsLoaded++;
        if(selectsLoaded >= $selects.length){
            if(initCallback) initCallback();
        }
    }
    function showFieldClearButton( $el ){
        $el.parent().find('.field-clear').hide();
        if( $el.val().length > 0 ) $el.parent().find('.field-clear').show();
    }
    function refresh(){
        console.log('refresh');
        for(var i=0;i<$selects.length;i++){
            $selects[i].multiselect( 'reload' );
            showFieldClearButton( $selects[i] );
        }
    }
    function clear(){
        console.log('clear');
        for(var i=0;i<$selects.length;i++) $selects[i].val('');
        refresh();
    }
    function destroy(){
        console.log('destroy');
        $('body').removeClass( readyClass );
        for(var i=0;i<$selects.length;i++){
            $selects[i].multiselect( 'unload' );
        }
    }
    function reset(){
        console.log('reset');
        destroy();
        setTimeout(init,1000)
    }
    return {
        init:init,
        reset:reset,
        clear:clear,
        destroy:destroy,
    }
})();

var TheMandarin_Tabs = (function(){
    function init(){
        $panels = $('.panel .tabs');
        $panels.each(function(){
            $panel = $(this).parent();
            $panel.find('.tab').click(function(){
                var id = $(this).attr('data-id');

                $panel.find('.tab').removeClass("active");
                $(this).addClass("active");

                $panel.find('.tab-content').hide();
                $panel.find('.tab-content#tab-id-'+id).show();
            });
            $panel.find('.tab').first().click();
        });
    }
    return {
        init:init
    }

})();

/* Run Scripts */
$(document).ready(function(e){
    SelectPlugin.init();
    TheMandarin_Tabs.init();
});
